import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";

const h2 = ({ children, className, textAlign }) => {
    return (
        <Styledh2 className={className} textAlign={textAlign}>
            {children}
            <hr />
        </Styledh2>
    );
};

h2.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    textAlign: PropTypes.oneOf(["left", "center"]),
};

h2.defaultProps = {
    className: "",
    textAlign: "left",
};

const Styledh2 = styled.h2`
    margin: 0 0 15px;
    position: relative;
    font-family: 'Playfair Display', serif;
    font-size: 1.75em;
    font-weight: bold;
    text-align: ${props => props.textAlign};
    hr {
        background-color: #355A2A;
        border: none;
        color: #355A2A;
        height: 5px;
        left: 0;
        margin-top: 2px;
        position: ${props => (props.textAlign === "center" ? "relative" : "absolute")};
        width: 150px;
    }
    @media (min-width: 800px) {
        margin: ${props => (props.textAlign === "center" ? "0 0 40px" : "0 0 15px")};
        hr {
            width: ${props => (props.textAlign === "center" ? "200px" : "150px")};
        }
        font-size: 2em;
    }
`;

export default h2;