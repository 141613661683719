import React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/layout'
import H1 from '../components/typography/h1'
import H2 from '../components/typography/h2'
import styled from "styled-components"
import {Helmet} from "react-helmet"

const Sales = ({ data }) => {
    // const { data } = this.props
    // const horses = data.allPrismicHorsesForSale.nodes
  
  return (
    <React.Fragment>
        <Layout>

          <Helmet>
              <meta charSet="utf-8" />
              <title>Horses for Sale | Collins Sporthorses</title>
              <meta name="description" content="Check out the current line up of Collins Sporthorses listed for sale. Located in Mayfield, Kentucky." />
          </Helmet>            

            <Container>
                <H1>Horses For Sales</H1>
                
                {/* { horses !== null &&
                    horses.map(({ data, uid }) => {
                        const price = new Intl.NumberFormat({ style: 'currency', currency: 'USD' }).format(data.price.text);
                        const link = `/horses-for-sale/${uid}`
                        return (
                            <div className="horse-listing">
                                <div className="horse-image">
                                  <img className="feature-image" src={data.picture_of_horse.url } alt={data.picture_of_horse.alt} />
                                </div>
                                <div className="horse-data">
                                  <H2>{data.horse_name.text}</H2>
                                  <ul>
                                      <li><strong>Price</strong>: ${price}</li>
                                      <li><strong>Gender</strong>: {data.gender}</li>
                                      <li><strong>Height</strong>: {data.hands.text}</li>
                                      <li><strong>Breed</strong>: {data.stud_book.text}</li>
                                  </ul>
                                  <StyledLink to={link}>View Horse</StyledLink>
                                  </div>
                            </div>
                        )
                    })
                } */}

                <div>
                  <H2>No horses for sale</H2>
                  <p>We currently do not have any horses posted for sale. If you would like to inquire about inventory not listed yet, please visit our <Link to='/contact'>contact page</Link>.</p>
                </div>
                

            </Container>
        
        </Layout>
        
    </React.Fragment>
  )
}

export default Sales

const Container = styled.div`
    width: 90%;
    max-width: 1000px;
    margin: 3em auto;
    .feature-image {
      width: 100%;
    }
    ul {
      list-style: none;
      padding-left: 0;
      li {
        padding: .25em 0;
        font-size: 1.25em;
        font-family: 'Lora';
      }
    }
    .horse-listing {
      border: solid 1px #CBCBCB;
      padding: 2em;
      margin: 1em 0;
      @media (min-width: 800px) {
        display: flex;
        .horse-image {
          width: calc(100%/2);
        }
        .horse-data {
          padding: 0 2em;
        }
      }
    }
}
`
const StyledLink = styled(props => <Link {...props} />) `
background-color: #355A2A;
border: 0;
color: white;
font-family: 'Playfair Display', serif;
padding: 1em 4em;
transition: ease .2s;
text-decoration: none;
position: relative;
display: inline-block;
:hover {
    background-color: #2A4822;
    cursor: pointer;
}`


// export const pageQuery = graphql`
//   query {
//     allPrismicHorsesForSale {
//         nodes {
//           data {
//             about_the_horse {
//               text
//             }
//             age {
//               text
//             }
//             hands {
//               text
//             }
//             horse_name {
//               text
//             }
//             picture_of_horse {
//               alt
//               url
//             }
//             price {
//               text
//             }
//             stud_book {
//               text
//             }
//             gender
//           }
//           uid
//         }
//       }
//   }
// `